define('ember-i18n-inject/initializers/i18n', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize() {
    // Support older and newer style initializer calls
    var application = arguments[1] || arguments[0];
    ['component', 'controller', 'model', 'route', 'view'].forEach(function (type) {
      application.inject(type, 'i18n', 'service:i18n');
    });
  }

  exports['default'] = {
    name: 'i18n',
    after: 'ember-i18n',
    initialize: initialize
  };
});